import React from "react"
import { useEffectOnce } from "react-use"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"
import { registerGlobalFunction } from "../register"

/**
 * Usage example:
 * <button 
    onClick='api.scrollToNextActionableBlock()'
    >
        Get Started
    </button>
 */

interface Props {}

const FuncScrollToNextActionableBlock: React.FC<Props> = () => {
  const { nextBlockType } = useNextActionableBlock("header")

  const scrollToNextActionableBlock = () => {
    if (nextBlockType && typeof window.scrollToElement === "function") {
      window.scrollToElement(nextBlockType)
    }
  }

  useEffectOnce(() => {
    if (window) {
      registerGlobalFunction(
        "scrollToNextActionableBlock",
        scrollToNextActionableBlock
      )
    }
  })

  return null
}

export default FuncScrollToNextActionableBlock
