import React from "react"
import { isBrowser } from "~/services/Utils"
import FuncAddProductToCart from "./functions/FuncAddProductToCart"
import FuncScrollToNextActionableBlock from "./functions/FuncScrollToNextActionableBlock"

interface Props {}

const GlobalFunctions: React.FC<Props> = () => {
  if (!isBrowser) {
    return null
  }

  return (
    <>
      <FuncAddProductToCart />
      <FuncScrollToNextActionableBlock />
    </>
  )
}

export default GlobalFunctions
