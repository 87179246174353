import styled from "@emotion/styled"

export const ProductGridBlockHeroStyle = styled.div<{
  pageLength: number
}>`
  width: 100%;
  @media (min-width: 1001px) {
    ${({ pageLength }) => `
    ${
      pageLength <= 2
        ? `
      width: 80%;
    `
        : ""
    }
    
  `}
  }

  @media (min-width: 1360px) {
    ${({ pageLength }) => `
    ${
      pageLength <= 2
        ? `
      width: 70%;
    `
        : ""
    }
  `}
  }
`
