import { Center, VStack } from "@chakra-ui/react"
import { BlockProductGridEntity } from "@jackfruit/common"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import Masonry from "react-masonry-css"
import { useCart } from "~/hooks/useCart"
import { usePagination } from "~/hooks/usePagination"
import { useProducts } from "~/hooks/useProducts"
import BlockTitle from "../BlockTitle"
import CssLoader from "../CssLoader"
import Pagination from "../Pagination"
import ProductGridItem from "../product-grid/ProductGridItem"
import Wrapper from "../Wrapper"
import "./ProductGridBlock.scss"
import { ProductGridBlockHeroStyle } from "./ProductGridBlockStyle"

export interface Props {
  config: BlockProductGridEntity
}

const ProductGridBlock: React.FC<Props> = ({ config }) => {
  const {
    itemsPerPage,
    layout,
    printServices,
    title,
    subtitle,
    isCTAButtonEnabled,
    ctaButtonText,
  } = config

  const { t } = useTranslation()
  const { cart } = useCart()
  const { fulfillment } = cart
  const printServiceConfig = printServices[fulfillment][0]
  const printServiceId = printServiceConfig?.id
  const productConfig = config.products[fulfillment]
  const configToUse = productConfig[+printServiceId] ?? {}

  const productIds = Object.values(configToUse)
    .sort((a, b) => b.position - a.position)
    .map(a => a.id)

  const { products } = useProducts(productIds)
  const filteredProducts = products.filter(Boolean)

  // Add productGrids to debugJson for test automation
  useEffect(() => {
    if (window.debugJson) {
      window.debugJson.productGridProducts = products
    }
  }, [products])

  const { page, currentPage, gotoPage } = usePagination(filteredProducts, {
    itemsPerPages: itemsPerPage,
  })

  // If no products are configured, show loading animation
  if (filteredProducts.length === 0) {
    return (
      <Center>
        <CssLoader label={t("components.blocks.TemplateBlockA.Loading")} />
      </Center>
    )
  }

  const pageLength = page.length
  const categoryName = products[0]?.categoryName || ""
  const isPhotoBook = categoryName === "photo-book"

  const breakpointColumns =
    layout === "horizontal"
      ? {
          default: pageLength > 3 ? 4 : 2,
          1360: pageLength >= 3 ? 3 : 2,
          1000: 2,
          768: 1,
        }
      : 1

  return (
    <Wrapper id="p-product-grid" py={16}>
      <BlockTitle title={title} subtitle={subtitle} />
      <VStack position="relative">
        <ProductGridBlockHeroStyle pageLength={pageLength}>
          <Masonry
            breakpointCols={breakpointColumns}
            className="product-grid"
            columnClassName="product-grid_column"
            style={{ width: "100%" }}
          >
            {page.map((product, index) => (
              <ProductGridItem
                key={product.id}
                index={index}
                product={product}
                layout={layout}
                isCTAButtonEnabled={isCTAButtonEnabled}
                ctaButtonText={ctaButtonText}
                isPhotoBook={isPhotoBook}
              />
            ))}
          </Masonry>
        </ProductGridBlockHeroStyle>
        <Pagination
          itemsPerPages={itemsPerPage}
          totalItems={products.length}
          onPageChange={gotoPage}
          selectedPage={currentPage}
        />
      </VStack>
    </Wrapper>
  )
}

export default ProductGridBlock
