import { Box, Button, Heading, Text } from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { OrderEntity } from "~/interfaces/entities/Order"
import { replaceShortCodes } from "~/services/replaceShortCodes"

interface Props {
  appConfig: AppEntityHydrated
  placedOrder: OrderEntity
  title: string
  body: string
}

const OrderThankYouText: React.FC<Props> = ({
  appConfig,
  placedOrder,
  title,
  body,
}) => {
  const { t } = useTranslation()
  const defaultTitle = t("components.blocks.OrderThankYouBlock.Title")
  const defaultBody = t("components.blocks.OrderThankYouBlock.Body", {
    name: "",
    appDisplayName: "[order field=appDisplayName]",
    dayOrEvening: "[order field=dayOrEvening]",
  })

  const { data: order } = placedOrder
  return (
    <Box px={4} py={{ base: 6, xl: 4 }}>
      <Heading
        as="h3"
        fontWeight="bold"
        fontSize={{
          base: "1.75rem",
          lg: "2rem",
        }}
        color="gray.800"
        lineHeight={{ md: 1 }}
      >
        {replaceShortCodes(title || defaultTitle, { appConfig, order })}
      </Heading>

      <Text
        textAlign="center"
        fontSize="md"
        color="gray.800"
        margin="0 auto"
        maxWidth="2xl"
        lineHeight={1.55}
        mt={{ base: 2, md: 4 }}
        mb={4}
      >
        {replaceShortCodes(body || defaultBody, { appConfig, order })}
      </Text>

      <Button
        colorScheme="primary"
        color="primary.text"
        as={Link}
        to={"/"}
        py={6}
        px={8}
        fontSize="1.2rem"
        mb={{ base: 32, md: 2, lg: 1 }}
        _hover={{ textDecoration: "none", bg: "primary.600" }}
      >
        {t("components.blocks.OrderCompleteBlock.Continue")}
      </Button>
    </Box>
  )
}

export default OrderThankYouText
