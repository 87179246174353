import React from "react"
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Label,
} from "recharts"

interface LineChartProps {
  data: any[]
}

const LineChartComponent: React.FC<LineChartProps> = ({ data }) => {
  const xAxisLabel = data.length > 0 ? Object.keys(data[0])[0] : ""
  const yAxisLabel = data.length > 0 ? Object.keys(data[0])[1] : ""

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        margin={{
          top: 5,
          right: 20,
          left: 5,
          bottom: 10,
        }}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisLabel}>
          <Label value={xAxisLabel} position="insideBottom" offset={-5} />
        </XAxis>
        <YAxis>
          <Label value={yAxisLabel} position="insideLeft" angle={-90} />
        </YAxis>
        <Tooltip />
        <Line type="monotone" dataKey={yAxisLabel} stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default LineChartComponent
