import React from "react"
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts"

interface ScatterGraphProps {
  data: any[]
}

const ScatterGraph: React.FC<ScatterGraphProps> = ({ data }) => {
  const name = data.length > 0 ? data[0] : []
  const xAxisName = name.x
  const yAxisName = name.y

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <XAxis type="number" dataKey="x" name={xAxisName}>
          <Label value={xAxisName} position="insideBottom" offset={-5} />
        </XAxis>
        <YAxis type="number" dataKey="y" name={yAxisName}>
          <Label value={yAxisName} position="insideLeft" angle={-90} />
        </YAxis>
        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
        <Scatter data={data} fill="#8884d8" />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default ScatterGraph
