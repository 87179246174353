import { BlockGraphEntityHydrated } from "@jackfruit/common"
import React from "react"
import { Box, Container } from "@chakra-ui/react"
import ScatterGraph from "./block-graph/ScatterGraph"
import StackedAreaChart from "./block-graph/StackedAreaChart"
import LineChartComponent from "./block-graph/LineChart"
import PieChartComponent from "./block-graph/PieChart"
import DonutComponent from "./block-graph/Donut"
import BarChartComponent from "./block-graph/BarChart"

interface Props {
  config: BlockGraphEntityHydrated
}

const GraphBlock: React.FC<Props> = ({ config }) => {
  if (!config) {
    return null
  }
  const selectedChartType = config.graphType
  const chartData = config.data
  const shouldShowGraph = Boolean(chartData && selectedChartType)

  let graphComponent = null

  switch (selectedChartType) {
    case "LineGraph":
      graphComponent = <LineChartComponent data={chartData} />
      break
    case "ScatterGraph":
      graphComponent = <ScatterGraph data={chartData} />
      break
    case "StackedAreaChart":
      graphComponent = <StackedAreaChart data={chartData} />
      break
    case "PieChart":
      graphComponent = <PieChartComponent data={chartData} />
      break
    case "BarGraph":
      graphComponent = <BarChartComponent data={chartData} />
      break
    case "Donut":
      graphComponent = <DonutComponent data={chartData} />
      break
    default:
      graphComponent = null
  }

  return (
    <Container
      fontSize="1rem"
      lineHeight="1.75"
      maxW="2xl"
      pt="var(--chakra-space-16)"
      pb="var(--chakra-space-16)"
      pl="var(--chakra-space-4)"
      pr="var(--chakra-space-4)"
    >
      {shouldShowGraph && <Box>{graphComponent}</Box>}
    </Container>
  )
}

export default GraphBlock
