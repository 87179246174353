import React from "react"
import {
  Flex,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from "@chakra-ui/react"

interface Props {
  featureImageUrl?: string
  link?: string
  title?: string
  subtitle?: string | null
  styles?: any
  displayTitles?: boolean
  displayPublishedAtDates?: boolean
}

const SubpageGridBlockCard: React.FC<Props> = ({
  featureImageUrl,
  link,
  title,
  subtitle,
  styles,
  displayTitles,
  displayPublishedAtDates,
}) => {
  const hideTitleAndDate = !displayTitles && !displayPublishedAtDates

  const isCenterAligned = !(displayTitles && displayPublishedAtDates)
  const centerAlignment = isCenterAligned
    ? { align: "center", justify: "center" }
    : {}

  return (
    <LinkBox
      bg="white"
      h={hideTitleAndDate ? "" : 360}
      maxW="400px"
      rounded="base"
      spacing={4}
      overflow="hidden"
      transition="all 0.15s ease-in-out"
      _hover={{ boxShadow: "0 0 6px 0 rgba(0,0,0,0.25)" }}
      {...styles}
    >
      <Flex as={LinkOverlay} href={link} direction="column" h="100%">
        <Image
          src={featureImageUrl}
          h={hideTitleAndDate ? "" : 250}
          objectFit="cover"
        />
        {!hideTitleAndDate && (
          <Stack h="100%" my={2} px={4} {...centerAlignment}>
            {displayTitles && (
              <Heading as="h3" size="md">
                {title}
              </Heading>
            )}
            {Boolean(subtitle && displayPublishedAtDates) && (
              <Text>{subtitle}</Text>
            )}
          </Stack>
        )}
      </Flex>
    </LinkBox>
  )
}

export default SubpageGridBlockCard
