import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  LinkBox,
  Text,
} from "@chakra-ui/react"
import { PrintServiceProductEntityHydrated } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useProcessActions } from "~/hooks/useProcessActions"
import { useProductImages } from "~/hooks/useProductImages"

interface Props {
  product: PrintServiceProductEntityHydrated
  layout: "vertical" | "horizontal"
  index: number
  isCTAButtonEnabled: boolean
  ctaButtonText: string
  isPhotoBook: boolean
}

const ProductGridItem: React.FC<Props> = ({
  product,
  layout,
  index,
  isCTAButtonEnabled,
  ctaButtonText,
  isPhotoBook,
}) => {
  const { t } = useTranslation()
  const { id: productId, shortDescription, longDescription } = product
  const { productImages } = useProductImages(productId)
  const process = useProcessActions()

  const productImage =
    productImages.find(image => image?.metaData?.web?.thumbnail === true) ??
    productImages[0]
  const imageObjectFit =
    productImage?.metaData?.web?.grid?.imageFillType || "cover"

  const isShowCTAButton = Boolean(isCTAButtonEnabled && layout === "horizontal")

  const handleSelectProduct = useCallback(() => {
    process.createLineItem({
      productId,
      createFromProduct: true,
    })
  }, [process, productId])

  return (
    <LinkBox
      role="group"
      bg="white"
      mx="auto"
      mb={4}
      rounded="base"
      overflow="hidden"
      boxShadow="md"
      cursor="pointer"
      onClick={handleSelectProduct}
      className={`product-grid-item product-grid-item-${layout} p-product-grid-item-${index}`}
      data-testid={product.id}
      transition="all 0.2s ease-in-out"
      _hover={{
        transform: "scale(1.03)",
        cursor: "pointer",
        boxShadow: "lg",
      }}
      aria-label="product-grid-item"
    >
      <Box className="product-grid-image">
        <Image
          src={productImage?.thumbnail || "https://via.placeholder.com/150"}
          objectFit={imageObjectFit}
          rounded={"base"}
          alt={shortDescription}
        />
      </Box>

      <Flex direction="column" p={3} gap={3} justifyContent="space-between">
        <Flex className="product-grid-name">
          <Heading
            as="h3"
            fontSize={{ base: "md", md: "lg" }}
            textOverflow="ellipsis"
            noOfLines={{ base: 2, lg: 1 }}
            lineHeight={1.5}
          >
            {shortDescription}
          </Heading>
        </Flex>

        {longDescription && isPhotoBook && (
          <Flex
            className="long-description"
            aria-label="photobook-long-description"
          >
            <Text textOverflow="ellipsis" noOfLines={2} color="gray.600">
              {longDescription}
            </Text>
          </Flex>
        )}

        {isShowCTAButton && (
          <Flex>
            <Button
              id={`cta-button--${productId}`}
              colorScheme="primary"
              color="primary.text"
              className="cta-button"
              size="sm"
              _groupHover={{ display: "inline-block" }}
            >
              {ctaButtonText ||
                t(
                  "components.components.product-grid.ProductGridItem.CTAButtonDefault"
                )}
            </Button>
          </Flex>
        )}
      </Flex>
    </LinkBox>
  )
}

export default ProductGridItem
