import { BlockVideoEntityHydrated } from "@jackfruit/common"
import React from "react"
import { useBreakpointValue, AspectRatio } from "@chakra-ui/react"

interface Props {
  config: BlockVideoEntityHydrated
}

const VideoBlock: React.FC<Props> = ({ config }) => {
  const maxW = useBreakpointValue({
    base: "80%",
    lg: "50%",
  })

  const extractVideoId = (url: string): string => {
    let videoId = ""
    const regex =
      /(?:https?:\/\/(?:www\.)?)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/embed\/)([\w-]+)/
    const match = url.match(regex)
    if (match && match[1]) {
      videoId = match[1]
    }
    return videoId
  }

  const videoId = extractVideoId(config.youtubeUrl)

  if (!videoId) {
    return null
  }

  const embedUrl = `https://www.youtube.com/embed/${videoId}`

  return (
    <AspectRatio maxW={maxW} ratio={16 / 9} mx="auto" mt={10} mb={10}>
      <iframe
        title={`youtube video - ${config.id}`}
        width="100%"
        height="100%"
        src={embedUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </AspectRatio>
  )
}

export default VideoBlock
