import { Heading, Text } from "@chakra-ui/react"
import React from "react"

interface Props {
  title: string
  subtitle: string
  titleId?: string
  subtitleId?: string
}

const BlockTitle: React.FC<Props> = ({
  title,
  subtitle,
  titleId,
  subtitleId,
}) => {
  return (
    <>
      {Boolean(title) && (
        <Heading
          id={titleId ?? "block-title"}
          textAlign="center"
          mt={0}
          mb={subtitle ? 2 : 12}
          fontSize="2xl"
          fontFamily="heading"
          fontWeight="semibold"
          color="gray.600"
          lineHeight="short"
        >
          {title}
        </Heading>
      )}
      {Boolean(subtitle) && (
        <Text
          id={subtitleId ?? "block-subtitle"}
          textAlign="center"
          maxW="800px"
          mx="auto"
          mb={12}
          mt={title ? 0 : 8}
        >
          {subtitle}
        </Text>
      )}
    </>
  )
}

export default BlockTitle
