import { Box, Button, Flex, Heading, Text, useTheme } from "@chakra-ui/react"
import { BlockImageUploadEntityHydrated } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { Trans, useTranslation } from "react-i18next"
import Wrapper from "~/components/Wrapper"
import { UPLOAD_ACCEPTED_FILE_TYPES } from "~/data/const"
import { useCurrentPageId } from "~/hooks/useCurrentPageId"
import { useNextActionableBlock } from "~/hooks/useNextActionableBlock"
import { usePageSession } from "~/hooks/usePageSession"
import { useProcessActions } from "~/hooks/useProcessActions"
import { ReactComponent as SVG } from "~/images/ImageUpload.svg"
import { Gtm } from "~/services/Gtm"
import { scrollTo } from "~/services/Utils"
import "./ImageUploadBlock.css"

interface Props {
  config: BlockImageUploadEntityHydrated
}

const ImageUploadBlock: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation()
  const pageId = useCurrentPageId()
  const { colors } = useTheme()
  const process = useProcessActions()
  const { nextBlockType } = useNextActionableBlock("image-upload")
  const {
    pageSession: { isReady },
  } = usePageSession()

  const onDrop = useCallback(
    (acceptedFiles: any[], _rejectedFiles: any) => {
      if (isReady && acceptedFiles.length > 0) {
        Gtm.fireFileUploadEvent(acceptedFiles.length)
        // upload the first image
        const firstImage = acceptedFiles[0]
        process.createUpload({
          pageId,
          file: firstImage,
          scrollTo,
          nextBlockName: nextBlockType,
        })

        const remainingImages = acceptedFiles.slice(1)
        // finish uploading images
        remainingImages.forEach((file: File) =>
          process.createUpload({
            pageId,
            file,
          })
        )
      }
    },
    [isReady, nextBlockType, pageId, process]
  )

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop,
    accept: UPLOAD_ACCEPTED_FILE_TYPES,
  })

  const topPart = config.topHtml ? (
    <Box dangerouslySetInnerHTML={{ __html: config.topHtml }} />
  ) : (
    <>
      <Heading as="h3" mb={2} fontSize="2xl">
        {t("components.blocks.ImageUploadBlock.UploadHeading")}
      </Heading>
      <Text textAlign="center" mb={8}>
        {t("components.blocks.ImageUploadBlock.UploadSubheading")}
      </Text>
    </>
  )

  const bottomPart = (
    <>
      {false && !!config.bottomHtml ? (
        <Box dangerouslySetInnerHTML={{ __html: config.bottomHtml }} />
      ) : (
        <Text mb={4} fontWeight="semibold">
          {t("components.blocks.ImageUploadBlock.DragNDrop")}
        </Text>
      )}
      <Text>
        <Trans i18nKey="components.blocks.ImageUploadBlock.DeviceWrapper" t={t}>
          <Button
            id="import-a-file-button"
            data-testid="import-a-file-button"
            {...getRootProps()}
            colorScheme="primary"
            color="primary.text"
            isDisabled={!isReady}
          />
        </Trans>
      </Text>
    </>
  )

  return (
    <Wrapper
      id="p-upload" // GTM: upload visible
      hasSeparator={true}
      paddingTop={16}
      borderTopColor="gray.200"
      borderTopWidth={1}
    >
      <Flex direction="column" alignItems="center">
        {topPart}
        <Box
          {...getRootProps()}
          id="image-upload-field"
          data-testid="image-upload-field"
          className="ImageUpload_svg"
          mb={8}
          p={4}
          position="relative"
        >
          <input {...getInputProps()} />
          <Box data-testid="input-upload-field" pointerEvents="none">
            <SVG
              data-testid="svg-image-upload-field"
              color={isDragReject ? colors.red[500] : colors.upload[400]}
            />
          </Box>
        </Box>
        {bottomPart}
      </Flex>
    </Wrapper>
  )
}

export default ImageUploadBlock
