// update module.d.ts to amend the window api object

import { camelCase } from "lodash"
import { MouseEvent } from "react"

export const registerGlobalFunction = (name: string, func: any) => {
  window.api = {
    ...window.api,
    [name]: func,
  }
}

export const parseArgsFromEvent = <T>(
  event: MouseEvent<HTMLButtonElement, MouseEvent>
) => {
  const target = event.target as HTMLButtonElement
  const { attributes } = target

  const parsed = Object.values(attributes)
    .filter(item => {
      const { name } = item
      return name.includes("data-")
    })
    .reduce((acc, item) => {
      const { name, value } = item
      const key = camelCase(name.replace("data-", ""))
      const shouldBeNumber = name.endsWith("-id")

      acc[key] = shouldBeNumber ? Number(value) : value

      return acc
    }, {} as any)

  return parsed as T
}
